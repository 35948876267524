const $ = jQuery;
let map, bounds, infowindow, markers = [];
const resultsContainer = $("#pmp-map-results");
const weekdays = ["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];
const stores = store_data;
const mapElem = (document.getElementById("map")) ? document.getElementById("map") : document.getElementById("mapsingle");

const initMap = () => {
	let centered = { lat: 51.5072, lng: 0.1276};

	if ( mapElem ) {
		bounds = new google.maps.LatLngBounds();
		infowindow = new google.maps.InfoWindow(); 
		map = new google.maps.Map(mapElem, {
			center: centered,
			zoom: 8,
			maxZoom: 10,
			styles: [
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#aad2e3"
						},
						{
							"visibility": "on"
						}
					]
				}
			]
		});

		if (stores.stores) {
			Object.entries(stores.stores).map((items, i) => {
				Object.entries(items[1]).map((item, i) => {
					const entry = item[1];
					if (entry.store.address) {
						let markerPos = { lat: parseFloat(entry.store.address.lat), lng: parseFloat(entry.store.address.lng) };
						let marker = new google.maps.Marker({
							map: map,
							position: markerPos
						});

						markers.push(marker);

						bounds.extend(marker.position);

						new google.maps.event.addListener(marker, 'click', (function (marker, i) {
							return function () {
								infowindow.setContent(entry.store.name);
								infowindow.open(map, marker);
							}
						})(marker, i));
						console.log('b',bounds);
						// map.fitBounds(bounds);
						map.center = bounds.getCenter();
						map.zoom = 10;

					}
				});
			});
		}
	}
}

const initMapSingle = () => {
	let centered = { lat: parseFloat(stores.stores?.premium[0]?.store?.address?.lat), lng: parseFloat(stores.stores?.premium[0]?.store?.address?.lng)};

	if ( mapElem ) {
		bounds = new google.maps.LatLngBounds();
		infowindow = new google.maps.InfoWindow(); 
		map = new google.maps.Map(mapElem, {
			center: centered,
			zoom: 1,
			styles: [
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#aad2e3"
						},
						{
							"visibility": "on"
						}
					]
				}
			]
		});

		if (stores.stores) {
			Object.entries(stores.stores).map((items, i) => {
				Object.entries(items[1]).map((item, i) => {
					const entry = item[1];
					if (entry.store.address) {
						let markerPos = { lat: parseFloat(entry.store.address.lat), lng: parseFloat(entry.store.address.lng) };
						let marker = new google.maps.Marker({
							map: map,
							position: markerPos
						});

						markers.push(marker);
						console.log(marker.position);
						bounds.extend(marker.position);

						new google.maps.event.addListener(marker, 'click', (function (marker, i) {
							return function () {
								infowindow.setContent(entry.store.name);
								infowindow.open(map, marker);
							}
						})(marker, i));
						console.log(bounds);
						map.fitBounds(bounds);
					}
				});
			});
		}
	}
}

// window.initMap = initMapSingle;
window.initMap = initMap;

// $(document).ready(function () {
// 	if (stores.online) {
// 		Object.entries(stores.online).map((store, i) => {
// 			const listing = '<div class="col-6 col-md-2"><div class="pmp-online-results--item"><a class="external-store-link" href="' + store[1].url + '" target="_blank"><img src="' + store[1].image + '" class="img-fluid" alt="' + store[1].name + '"/><span class="d-block">Visit site</span></a></div></div>';
// 			$('#pmp-online-results').append(listing);
// 		});
// 	}
// });


// $('.pmp-store-locator [name="store-type"], .pmp-store-locator [name="country"]').on('change', function (e) {
// 	e.preventDefault();
// 	get_form_data();
// });

// $('.pmp-store-locator form').on('submit', function (e) {
// 	e.preventDefault();
// 	get_form_data();
// });

